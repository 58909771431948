class ProductSession {
  static getTitle(product) {
    switch (product.toLocaleLowerCase()) {
      case "digital":
      case "digital2":
      case "digital2cs":
      case "digital3":
      case "digital7":
      case "digital7cs":
      case "digital7ds":
      case "digital7loja":    
      case "digitalanual":
      case "digitalc1":
      case "digitalc2":
      case "digitalc3":
      case "digitalc4":
      case "digitalc5":
      case "digitalc6":
      case "digitalc7":
      case "digitalc8":
      case "digitalc9":
      case "digitalc10":
      case "digitalc11":
      case "digitalc12":
        return "NSC Digital";

      case "clube":
      case "clube2":
      case "clubepromo":
      case "clube2cs":
      case "clube3":
      case "clube4":
      case "clube6":
      case "clube6meses":  
      case "clube6mesescs":
      case "clube6mesesloja":
      case "clubeweekads":  
      case "clubeweekcs":
      case "clubeweekloja": 
      case "clube7":
      case "clube8":
      case "clube9":
      case "clube10":
      case "clubeoab":
      case "clubesimesc":
      case "clubeuni":    
      case "clube11":
      case "clube12": 
      case "clube4cs":
      case "clube5":
      case "clube2x":
      case "clube2xcs":
      case "clubebrinde":
      case "club1":
      case "club2":
      case "club3":
      case "club4":
      case "club5":
      case "club6":
      case "club7":
      case "club8":
      case "club9":
      case "club10":
      case "club11":
      case "club12":
        return "NSC Digital + Clube NSC";

      case "combolight":
        return "NSC Digital + Jornal Semanal";

      case "globo":
      case "globoanual":
        return "NSC Total & Globo +";
      case "digital5":
      case "digital6":
      case "digital5cs":
        return "NSC Total Light Anual";
      default:
        return "Unkown";
    }
  }

  static getAdvantages(product) {
    switch (product.toLocaleLowerCase()) {
      case "digital":
      case "digital2":
      case "digital2cs":
      case "digital3":
      case "digital4":
      case "digitalanual":
      case "digitalc1":
      case "digitalc2":
      case "digitalc3":
      case "digitalc4":
      case "digitalc5":
      case "digitalc6":
      case "digitalc7":
      case "digitalc8":
      case "digitalc9":
      case "digitalc10":
      case "digitalc11":
      case "digitalc12":
        return [
          "Acesso ilimitado às <b>notícias</b> e <b>colunistas</b> do NSC Total",
          "Acesso aos <b>jornais</b> DC, AN e SANTA na versão digital",
          "Conteúdo das <b>rádios</b> CBN Floripa e CBN Joinville",
          "<b>Aplicativo NSC Total</b> com acesso ilimitado e possibilidade de personalização do conteúdo",
        ];

      case "clube":
      case "club1":
      case "clube2":
      case "clubepromo":
      case "clube3":
      case "clube4":
      case "clube6":
      case "clube6meses": 
      case "clube6mesescs": 
      case "clube6mesesloja":  
      case "clubeweekads":  
      case "clubeweekcs":
      case "clubeweekloja": 
      case "clube7":
      case "clube8":
      case "clube2":
      case "clube9":
      case "clube10":
      case "clubeoab":
      case "clubesimesc":
      case "clubeuni": 
      case "clube11":
      case "clube12":        
      case "clube5":
      case "clube2x":
      case "clube2xcs": 
      case "clube2cs":
      case "clube4cs":
      case "clubebrinde":
      case "club1":
      case "club2":
      case "club3":
      case "club4":
      case "club5":
      case "club6":
      case "club7":
      case "club8":
      case "club9":
      case "club10":
      case "club11":
      case "club12":
        return [
          "<b>Todas as vantagens do plano NSC Digital mais:</b>",
          "Torne-se sócio do <b>Clube NSC</b>, maior clube de vantagens de Santa Catarina",
          "Aproveite os <b>benefícios</b> e <b>descontos</b> em mais de 500 parceiros, físicos, online e com delivery, em SC e no Brasil",
          "Possibilidade de incluir até <b>3 dependentes</b> no Clube NSC",
        ];

      case "combolight":
        return [
          "<b>Acesso ilimitado ao NSC Total</b>",
          "<b>Jornais DC, AN e Santa na versão digital</b>",
          "<b>Clube NSC</b> - Descontos e benefícios em mais de <b>400 parceiros</b> em SC",
          "<b>Jornal impresso na sua casa no final de semana.</b>",
        ];

      case "globo":
      case "globoanual":
        return [
          "Acesso ilimitado às <b>notícias</b> e <b>colunistas</b> do NSC Total",
          "Três meses de acesso liberado à plataforma <b>Globo+</b>",
          "Acesso aos <b>jornais</b> DC, AN e SANTA na versão digital",
          "Conteúdo das <b>rádios</b> CBN Floripa e CBN Joinville",
          "<b>Aplicativo NSC Total</b> com acesso ilimitado e possibilidade de personalização do conteúdo",
        ];

        case "digital5":
        case "digital6":
        case "digital7":
        case "digital7cs":
        case "digital7ds":
        case "digital7loja":  
        case "digital5cs":
          return [
            "Acesso ilimitado às <b>notícias</b> e <b>colunistas</b> do NSC Total",
            "Acesso aos <b>jornais</b> DC, AN e SANTA na versão digital",
            "Conteúdo das <b>rádios</b> CBN Floripa, CBN Joinville",
            "<b>Aplicativo NSC Total</b> com acesso ilimitado e possibilidade de personalização do conteúdo",
          ];

      default:
        break;
    }
  }

  static buildPrice(price, discounts) {
    let priceWithDiscount =
      parseFloat(price) -
      discounts.reduce((total, item) => parseFloat(total) + parseFloat(item));
    return priceWithDiscount.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
  }
  static getPeriod(discountPeriod) {
    let meses;

    discountPeriod == 1 ? (meses = "mês") : (meses = "meses");

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      token.replace("?url_state=", "");
    }

    if (token == "a78tn31m456hgj8g6sdf1f8ew12q88s9") {
      meses = "mes";
    }

    switch (parseInt(discountPeriod)) {
      case 0:
      case Number.isNaN:
        return "";

      case 1:
        return "No primeiro mês";

      default:
        return "Por " + discountPeriod + " " + meses + "";
    }
  }
}

export default ProductSession;
